import React from 'react';
import { ReactComponent as BipPackagesIcon } from 'images/bip-packages.svg';
import { colors } from '@loggi/mar';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CONTENT_TEXT } from '../drawer-group-packages.constants';
import { useDrawerGroupPackages } from '../provider/drawer-group-packages.context';
import CardPackageInfoV1Container from '../card-package-info-v1';

export default function ListPackagesBeepedV1Component({
  loading,
  loadingEditData
}) {
  const { listPackagesBeepedContext } = useDrawerGroupPackages();

  return (
    <>
      {listPackagesBeepedContext?.length > 0 && !loading && (
        <Box pb={2}>
          <Typography variant="subtitle1">
            <strong>{CONTENT_TEXT.title}</strong>
          </Typography>
          <Typography variant="h4">
            {listPackagesBeepedContext.length}{' '}
            {listPackagesBeepedContext.length === 1
              ? CONTENT_TEXT.count_package
              : CONTENT_TEXT.count_packages}
          </Typography>
        </Box>
      )}
      {listPackagesBeepedContext?.length > 0 && !loadingEditData && (
        <>
          {loadingEditData ? (
            <Box
              pt={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            listPackagesBeepedContext.map(pkg => (
              <CardPackageInfoV1Container
                key={pkg.packageId}
                packageInfo={pkg}
              />
            ))
          )}
        </>
      )}
      {listPackagesBeepedContext?.length === 0 && !loading && !loadingEditData && (
        <>
          <Box
            height={124}
            width={118}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <BipPackagesIcon />
          </Box>
          <Box pt={1}>
            <Typography variant="subtitle1">
              <strong>{CONTENT_TEXT.empty_list}</strong>
            </Typography>
          </Box>
          <Box pt={2.5} color={colors.smoke[700]}>
            <Typography variant="subtitle2">
              {CONTENT_TEXT.instructions}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}

ListPackagesBeepedV1Component.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingEditData: PropTypes.bool.isRequired
};
