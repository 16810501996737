import theme, { colors } from '@loggi/mar';
import { createMuiTheme } from '@material-ui/core/styles';
import datePickerStyle from 'view/theme/date-picker.styles';
import chipStyle from 'view/theme/chip.styles';

export default createMuiTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    // We are extending `Mar's Theme` until dateRangePicker is incorporated there
    ...datePickerStyle,
    ...chipStyle,
    MuiTypography: {
      colorTextSecondary: {
        color: colors.smoke[500]
      },
      colorInherit: {
        color: colors.yellow[900]
      }
    },
    MuiCssBaseline: {
      '@global': {
        em: {
          fontWeight: 600,
          fontStyle: 'normal'
        }
      }
    }
  }
});
