export const TEXTS = {
  PROFILE_TRANSPORT: 'De ',
  PROFILE_CPF: 'CPF:',
  PICKUP_SNACKBAR_MESSAGE:
    'Pronto! Agora essa pessoa pode fazer coletas com o app Loggi para entregador.',
  PICKUP_SNACKBAR_MESSAGE_NO_DC:
    'Pronto! Agora essa pessoa pode fazer atividades no app Loggi para entregador.',
  SNACKBAR_MESSAGE:
    'Tudo certo, link copiado. Agora, é só enviar pelo WhatsApp para quem vai entregar.',
  DELIVERY_TITLE: 'Faz entregas de pacotes',
  DELIVERY_SUBTITLE: [
    'Envie o ',
    'link de acesso ',
    'para quem vai fazer as entregas de pacotes da base.'
  ],
  DELIVERY_BUTTON: 'Copiar link de acesso',
  ACTIVITY: {
    TITLE: 'Pode fazer atividades no app',
    SUBTITLE: [
      'Usa o ',
      'app Loggi para entregadores ',
      'para fazer as atividades de coleta e entrega.'
    ]
  },
  PICKUP: {
    TITLE: 'Recebe ofertas no aplicativo',
    SUBTITLE: [
      'Usa o ',
      'app Loggi para entregadores ',
      'para receber e fazer ofertas de coleta.'
    ]
  },
  EDIT_DRIVER: {
    TITLE: 'Editar cadastro',
    SUBTITLE:
      'Você pode editar a qualquer momento o tipo de veículo para fazer as atividades.',
    EDIT_CONFIRMATION_TITLE: 'Tem certeza que quer atualizar o cadastro?',
    EDIT_CONFIRMATION_DESCRIBE:
      'Os dados desta pessoa vão ser atualizados pelas informações inseridas, ok?'
  },
  COPY_LINK_APP_STORE_BUTTON: 'Copiar link do app Loggi para entregadores',
  WARNING_TITLE: 'Atenção',
  ACTIVITY_WARNING_TEXT:
    'Sem a permissão, não é possível fazer nada no aplicativo Loggi para entregador.',
  PICKUP_WARNING_TEXT:
    'Se aceitarem uma oferta, as entregas em andamento vão parar. Primeiro, é necessário finalizar as coletas.',
  PROFILE_OLD_MIGRATION_TEXT:
    'Essa pessoa está fazendo uma entrega. Não é possível concluir o cadastro agora. Tente de novo quando a atividade terminar.',
  PROFILE_OLD_WARNING_TEXT:
    'Para ficar disponível, complete o cadastro e permita o envio de ofertas pelo app Loggi para entregadores. Tenha em mãos:',
  PROFILE_OLD_WARNING_DOCS: ['Nome completo', 'CPF', 'E-mail'],
  PENDING_STATUS_WARNING_TEXT: `Peça ao entregador para acessar o aplicativo de entregas e adicionar uma foto. 
    Dessa forma será possível habilitar a visualização de ofertas por tipo de veículo.`,
  COMPLETE_REGISTER_BUTTON: 'Completar cadastro',
  DELETE_REGISTER_TITLE: 'Tem certeza que quer excluir o cadastro?',
  DELETE_REGISTER_DESCRIBE:
    'Se você continuar, essa pessoa não vai mais poder fazer coletas ou entregas com os aplicativos da Loggi, ok?',
  DELETE_REGISTER_BACK: 'Voltar',
  DELETE_REGISTER_BUTTON: 'Excluir cadastro',
  EDIT_REGISTER_BUTTON: 'Editar cadastro',
  WARNING_HAS_PACKAGE_TITLE: 'Atenção',
  WARNING_HAS_PACKAGE_TEXT:
    'Não é possível editar informações de cadastro enquanto este entregador estiver com pacotes.',
  WARNING_HAS_PACKAGE_DELETE_TEXT:
    'Não é possível excluir o cadastro enquanto este entregador estiver com pacotes.'
};

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.loggi.driverapp';
