import React from 'react';

import Button from '@material-ui/core/Button';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { PropTypes } from 'prop-types';
import { DIALOG_BODY, OK, TO_GUIDE } from './constants';

export default function DialogExtendedDeadlineComponent({
  openDialog,
  setOpenDialog
}) {
  return (
    <ConfirmDialogComponent
      open={openDialog}
      descriptionStylized={DIALOG_BODY.description}
    >
      <>
        <Button
          onClick={() => {
            setOpenDialog(false);
            window.open(
              'https://ajuda.loggi.com/hc/pt-br/articles/30400871243021-O-que-significa-a-indica%C3%A7%C3%A3o-Prazo-extra-aplicado-em-alguns-pacotes',
              '_blank',
              'noopener'
            );
          }}
          color="primary"
          data-testid="confirmation-dialog-guide-btn"
        >
          {TO_GUIDE}
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          color="primary"
          variant="contained"
          data-testid="confirmation-dialog-submit-btn"
        >
          {OK}
        </Button>
      </>
    </ConfirmDialogComponent>
  );
}

DialogExtendedDeadlineComponent.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};
