import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '@loggi/mar';

const useStyles = makeStyles(({ palette, spacing, typography }) =>
  createStyles({
    divDrawer: {
      '& .MuiDrawer-paper': {
        width: '100%'
      }
    },
    bolder: {
      fontWeight: 700
    },
    semiBolder: {
      fontWeight: 600
    },
    fontSizeIcon: {
      fontSize: typography.pxToRem(14)
    },
    root: {
      borderColor: colors.storm[100]
    },
    item: {
      padding: 0,
      margin: 0
    },
    storm: {
      color: colors.storm[500]
    },
    smoke: {
      color: colors.smoke[700]
    },
    listItemLabel: {
      flexGrow: 1
    },
    faltaBackground: {
      backgroundColor: colors.red[50]
    },
    smallTitle: {
      fontWeight: 700,
      marginBottom: 5
    },
    roundedImage: {
      width: 67.5,
      height: 67.5
    },
    delivererImage: {
      display: 'flex',
      alignItems: 'center'
    },
    valueWithDiscount: {
      fontWeight: 400,
      fontSize: 24,
      textDecoration: 'line-through',
      textDecorationThickness: 1
    },
    discount: {
      color: colors.red[500],
      backgroundColor: colors.red[50],
      fontWeight: 600,
      fontSize: 14,
      padding: 5,
      marginLeft: 10
    },
    customAccordion: {
      backgroundColor: colors.smoke[100]
    },
    expandIcon: {
      color: colors.blue[500]
    },
    chip: {
      margin: spacing(0.5),
      borderRadius: 0,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.smoke[50]
    },
    extendedDeadlineChip: {
      backgroundColor: palette.primary.light,
      color: palette.grey[900],
      height: spacing(4),
      margin: spacing(1),
      padding: spacing(0.25)
    },
    extendedDeadlineChipLabel: {
      fontSize: '12px',
      fontWeight: 700
    },
    extendedDeadlineChipIconSvg: {
      width: '16px',
      height: '16px'
    }
  })
);

export default useStyles;
