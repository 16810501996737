import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CustomDrawer, DrawerHeader, DrawerContent } from 'shared/drawer';
import {
  emailIsValid,
  isCpfValid,
  nameIsValid,
  phoneIsValid
} from 'shared/validators';
import formatPhone from 'shared/formatters/phone/phone';
import { Box, TextField, Typography } from '@material-ui/core';
import formatCpf from 'shared/formatters/cpf';
import transportTypes from 'mocks/transport-types';
import { SWITCHES_REALTIME } from 'view/constants';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { getTransportTypesService } from '../drivers.service';
import DriversProfileDeleteComponent from './drivers-profile-delete.component';
import DriversProfileUpdateComponent from './drivers-profile-update.component';
import WarningContainer from './drivers-profile-warning.component';
import { TEXTS } from './drivers-profile.constants';
import { TRANSPORT_TYPE } from '../drivers.constants';

const DriversEditContainer = ({
  open,
  profile,
  onDelete,
  onEdit,
  handleClosingDrawer,
  setOpenProfile
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [transportsTypes, setTransportsTypes] = useState([]);
  const { hasPackage } = profile?.deliverer || {};
  const skipPackageCheckOnDriverUpdateFS = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableSkipPackageCheckOnDriverUpdate
  );

  function driverTransportType() {
    const driverTransport = transportTypes.find(
      option =>
        option.name.toLowerCase() ===
        TRANSPORT_TYPE[(profile.deliverer?.transportType)]
    );
    return driverTransport?.id;
  }

  const initialState = () => ({
    fullName: profile?.fullName || '',
    cpf: profile?.deliverer?.cpf || '',
    email: profile?.email || '',
    mobileNumber: formatPhone(profile?.mobileNumber || ''),
    transportType: driverTransportType()
  });
  const [formData, setFormData] = useState(initialState());
  const handleTyping = (value, field, format) => {
    setFormData(form => {
      return {
        ...form,
        [field]: format ? format(value) : value
      };
    });
  };

  const validateField = (validation, field) => {
    if (formData[field]) {
      setFormErrors(_errors => ({
        ..._errors,
        [field]: !validation(formData[field])
      }));
    }
  };

  const getErrorMessage = (field, key) =>
    formData[key] && formErrors[key] && field.texts.error;
  const firstEmptyOption = {};
  const options = [firstEmptyOption].concat(transportsTypes).map(option => (
    <option id={`opt-${option.name}`} key={option.name || 0} value={option.id}>
      {option.name}
    </option>
  ));
  const errors = Object.values(formErrors);
  const hasErrors = !errors.length || errors.some(Boolean);

  const inputs = {
    fullName: {
      validate: nameIsValid,
      texts: { texts: profile.fullName, label: 'Nome completo' },
      maxLength: 64
    },
    cpf: {
      validate: isCpfValid,
      texts: { texts: profile.deliverer?.cpf, label: 'CPF' },
      format: formatCpf,
      maxLength: 14
    },
    email: {
      validate: emailIsValid,
      texts: { texts: profile.email, label: 'E-mail' },
      type: 'email'
    },
    mobileNumber: {
      validate: phoneIsValid,
      format: formatPhone,
      texts: { texts: profile.mobileNumber, label: 'Celular' },
      maxLength: 15
    },
    transportType: {
      validate: Boolean,
      texts: {
        texts: TRANSPORT_TYPE[(profile.deliverer?.transportType)],
        label: 'Veículo'
      },
      isSelect: true,
      options
    }
  };

  const inputFactory = ([key, input], idx) => (
    <Box key={key} pt={idx && 2.5}>
      <TextField
        select={input.isSelect}
        SelectProps={{
          displayEmpty: true,
          native: true
        }}
        disabled={key === 'cpf'}
        error={Boolean(formData[key] && formErrors[key])}
        helperText={getErrorMessage(input, key)}
        fullWidth
        InputLabelProps={formData[key] ? { shrink: true } : {}}
        inputProps={{ 'data-testid': key, maxLength: input.maxLength }}
        label={input.texts.label}
        name={key}
        onBlur={() => validateField(input.validate, key)}
        onChange={e => handleTyping(e.target.value, key, input.format)}
        value={formData[key]}
        variant="outlined"
        type={input.type}
      >
        {input.options}
      </TextField>
    </Box>
  );

  useEffect(() => {
    getTransportTypesService()?.then(setTransportsTypes);
  }, [setTransportsTypes]);

  return (
    open && (
      <CustomDrawer open={open}>
        <DrawerHeader handleClosingDrawer={handleClosingDrawer} open={open}>
          <Box pt={2}>
            <Typography variant="h3">
              <em>{TEXTS.EDIT_DRIVER.TITLE}</em>
            </Typography>
          </Box>
          <Box pt={2.5} display="flex">
            <Typography variant="body1">
              {TEXTS.EDIT_DRIVER.SUBTITLE}
            </Typography>
          </Box>
          {hasPackage && (
            <WarningContainer
              title={TEXTS.WARNING_HAS_PACKAGE_TITLE}
              text={
                skipPackageCheckOnDriverUpdateFS
                  ? TEXTS.WARNING_HAS_PACKAGE_DELETE_TEXT
                  : TEXTS.WARNING_HAS_PACKAGE_TEXT
              }
            />
          )}
        </DrawerHeader>
        <DrawerContent>
          {Object.entries(inputs).map(inputFactory)}
        </DrawerContent>
        <DrawerContent>
          <DriversProfileUpdateComponent
            profile={profile}
            updatedProfile={formData}
            onEdit={onEdit}
            hasErrors={hasErrors}
            handleClosingDrawer={handleClosingDrawer}
            setOpenProfile={setOpenProfile}
          />
        </DrawerContent>
        <DrawerContent>
          <DriversProfileDeleteComponent
            profile={profile}
            onDelete={onDelete}
          />
        </DrawerContent>
      </CustomDrawer>
    )
  );
};

DriversEditContainer.propTypes = {
  profile: PropTypes.shape(),
  open: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  handleClosingDrawer: PropTypes.func.isRequired,
  setOpenProfile: PropTypes.func.isRequired
};

DriversEditContainer.defaultProps = {
  open: true,
  profile: {}
};

export default DriversEditContainer;
