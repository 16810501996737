export const TEXT = {
  TITLE: 'Identificamos pacotes inválidos',
  DESCRIPTION:
    'Validamos {totalPackages} {totalPackagesLabel}. Removemos {invalidPackages} {invalidPackagesLabel} abaixo:',
  EDIT_BUTTON: 'Voltar para edição',
  GROUP_BUTTON: 'Remover e continuar',
  PACKAGE: 'pacote',
  PACKAGES: 'pacotes',
  DEADLINE: 'até',
  WAIT: 'Aguardar',
  DELIVERY: 'Entregar',
  TEXT_BTN_LEFT: 'Text Button Left',
  TEXT_BTN_RIGHT: 'Text Button Right',
  STATUS: 'Status',
  WARNING:
    'Os pacotes abaixo não podem ser agrupados e serão removidos ao continuar. Certifique-se de retirar fisicamente esses pacotes do grupo.'
};

export const formatDescription = (
  description,
  totalPackages,
  invalidPackages
) => {
  const totalPackagesLabel = totalPackages === 1 ? TEXT.PACKAGE : TEXT.PACKAGES;
  const invalidPackagesLabel =
    invalidPackages === 1 ? TEXT.PACKAGE : TEXT.PACKAGES;

  return description
    .replace('{totalPackages}', `<em>${totalPackages}</em>`)
    .replace('{totalPackagesLabel}', `<em>${totalPackagesLabel}</em>`)
    .replace('{invalidPackages}', `<em>${invalidPackages}</em>`)
    .replace('{invalidPackagesLabel}', `<em>${invalidPackagesLabel}</em>`);
};
