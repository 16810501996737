import React from 'react';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { PACKAGE_DIRECTION_RETURN } from 'view/molecules/package-drawer/drawer.constants';

const AlertValidationComponent = ({ lastPackageWithValidation }) => {
  const chooseAddressInfo = () => {
    const isReturnDirection =
      lastPackageWithValidation?.direction &&
      lastPackageWithValidation?.direction === PACKAGE_DIRECTION_RETURN;

    if (isReturnDirection) {
      const { postalAddress } = lastPackageWithValidation?.loggiAddress || {};
      return postalAddress?.locality;
    }
    return lastPackageWithValidation?.destination?.city;
  };

  return (
    <>
      <Box
        data-testid="box-alert-validation-component"
        maxWidth="480px"
        mt={1}
        px={2}
        py={2}
        bgcolor={
          lastPackageWithValidation?.isGroupable
            ? colors.yellow[50]
            : colors.red[50]
        }
        borderRadius={8}
      >
        <Box mb={2}>
          <Typography
            color={lastPackageWithValidation?.isGroupable ? 'inherit' : 'error'}
            variant="subtitle2"
          >
            <em>{lastPackageWithValidation?.validationMessage}</em>
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle2">Detalhe do pacote</Typography>
          <Box my={1}>
            <Typography variant="body2">
              <em>{lastPackageWithValidation?.integrationInfo?.barcode}</em>
            </Typography>
            <Typography variant="body2">
              <em>{lastPackageWithValidation?.company?.name}</em>
            </Typography>
          </Box>
          <Typography variant="subtitle2">{chooseAddressInfo()}</Typography>
        </Box>
      </Box>
    </>
  );
};

AlertValidationComponent.propTypes = {
  lastPackageWithValidation: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    destination: PropTypes.shape({
      city: PropTypes.string
    }).isRequired,
    integrationInfo: PropTypes.shape({
      package_id: PropTypes.string.isRequired,
      barcode: PropTypes.string.isRequired
    }).isRequired,
    status: PropTypes.shape({
      code: PropTypes.number.isRequired
    }).isRequired,
    direction: PropTypes.string.isRequired,
    loggiAddress: PropTypes.shape({
      postalAddress: PropTypes.shape({
        addressLines: PropTypes.arrayOf(PropTypes.string)
      }),
      coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
      }),
      coordinatesPostalCode: PropTypes.string,
      correiosAddress: PropTypes.shape({
        logradouro: PropTypes.string,
        numero: PropTypes.string,
        bairro: PropTypes.string,
        cep: PropTypes.string,
        cidade: PropTypes.string,
        uf: PropTypes.string
      }),
      qualityFlags: PropTypes.shape()
    }).isRequired,
    isGroupable: PropTypes.bool,
    validationMessage: PropTypes.string
  }).isRequired
};

export default AlertValidationComponent;
