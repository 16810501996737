import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  dialogBorder: {
    '& div': {
      borderRadius: spacing(1.5)
    }
  },
  dialogWithoutPadding: {
    padding: spacing(0)
  },
  dialogContent: {
    '&.MuiDialogContent-root': {
      '&:first-child': {
        paddingTop: 0
      }
    }
  }
}));

export default useStyles;
