import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  PACKAGE_STATUS_CODE,
  PACKAGE_DIRECTION_RETURN
} from 'view/molecules/package-drawer/drawer.constants';
import CardPackageInfoV1 from './card-package-info-v1.component';
import { CONTENT_TEXT } from '../drawer-group-packages.constants';
import { useDrawerGroupPackages } from '../provider/drawer-group-packages.context';

export default function CardPackageInfoV1Container({ packageInfo }) {
  const {
    removePackageListPackagesBeepedContext,
    contextLastPackageWithValidation,
    contextLastPackageBeepSuccess
  } = useDrawerGroupPackages();

  // remove package in context too
  const removePackageFromListContext = id => {
    removePackageListPackagesBeepedContext(id);
    contextLastPackageWithValidation(null);
    contextLastPackageBeepSuccess(null);
  };

  const isAwaitingResolution =
    packageInfo?.status.code === PACKAGE_STATUS_CODE.AWAITING_RESOLUTION;

  const isReturnDirection =
    packageInfo?.direction &&
    packageInfo.direction === PACKAGE_DIRECTION_RETURN;

  const chooseTextPromisedDate = () => {
    return isAwaitingResolution
      ? CONTENT_TEXT.wait_text
      : CONTENT_TEXT.delivery_text;
  };

  // TODO: Condense this logic in a shareable function, since in drawer-detail.component.js
  // we need the same logic.
  const choosePromisedDate = () => {
    if (isAwaitingResolution) {
      return packageInfo?.awaitingResolutionPromisedDate?.promisedDate
        ?.promisedDate;
    }
    // Considering that the "Para transferir" section will only be released for LEVES.
    return packageInfo.lastMilePromisedDate?.promisedDate?.promisedDate;
  };

  const chooseAddressInfo = () => {
    if (isReturnDirection) {
      const { postalAddress } = packageInfo.loggiAddress || {};
      return postalAddress.locality;
    }
    return packageInfo.destination.city;
  };

  return (
    <Box>
      <CardPackageInfoV1
        packageInfo={packageInfo}
        addressInfo={chooseAddressInfo}
        promisedDate={choosePromisedDate}
        promisedDateText={chooseTextPromisedDate}
        removePackageFromList={removePackageFromListContext}
        hideTrashButton={packageInfo?.currentGroupThisPackage}
      />
    </Box>
  );
}

CardPackageInfoV1Container.propTypes = {
  packageInfo: PropTypes.shape().isRequired
};
