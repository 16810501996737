import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ReactComponent as ICBase } from 'images/ic-base.svg';

import { Can, CanFunction } from 'auth/access-control';
import { getCompanyType } from 'auth/access-control/access-control.service';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import AlertStatusHeader from 'shared/alert-status-header';
import DialogExtendedDeadlineComponent from 'shared/confirm-dialog/extended-deadline';
import { SummaryContext } from 'tracking/template/summary.context';
import { useStyles } from 'tracking/tracking.styles';
import {
  KEY_CUSTODY,
  TEXT_ALERT_STATUS_HEADER
} from 'tracking/tracking.constants';
import AsyncPackages from 'tracking/async-packages';
import DrawerGroupPackagesContainer from 'tracking/drawer-group-packages/drawer-group-packages.container';
import { SEPARATION_CONTEXT } from 'tracking/drawer-group-packages/drawer-group-packages.constants';
import { CardReceivePackageContainer } from 'tracking/card-receive-package';
import BaseContext from 'tracking/in-base/base.context';
import CUSTODY_MAP from 'tracking/tracking.configuration';
import { generateCsvLink } from 'tracking/tracking.service';
import clsx from 'clsx';
import { useStyles as useStylesIcon } from 'shared/card-icon/card-icon.styles';
import { SWITCHES_REALTIME } from 'view/constants';
import {
  CardFrame,
  CardBase,
  CardFrameSkeleton
} from './base-collection.component';
import {
  TEXT,
  TEXT_DEADLINE,
  DEADLINE_LABEL_TO_ROUTE,
  ATTENTION_LABEL_TO_ROUTE,
  POSITION_LABEL_TO_ROUTE,
  TEXT_ATTENTION,
  TEXT_POSITION
} from './base-collection.constants';
import BaseFilter from './base-collection-filter.component';
import BaseHeaderButtons from '../shared/base-header-buttons.component';

const DRAWERS = {
  GROUPING: 'GROUPING'
};

export default function BaseContainer() {
  const classes = useStyles();
  const { setSummary } = useContext(SummaryContext);
  const {
    aggregationDeadline,
    aggregationAttention,
    aggregationPosition,
    totalPackages,
    setAlertContent
  } = useContext(BaseContext);

  const [currentDrawer, setCurrentDrawer] = useState('');
  const [downloadCsvLink, setDownloadCsvLink] = useState('');
  const [
    showExtendedDeadlineOnboarding,
    setShowExtendedDeadlineOnboarding
  ] = useState(false);

  const enableExtendedDeadline = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableExtendedDeadline
  );

  const content = CUSTODY_MAP[KEY_CUSTODY.NA_BASE_PACOTES];

  const hasCirclesEnabled = getCompanyType() === 'LEVE';

  const classesIcon = useStylesIcon();

  const loading =
    !aggregationDeadline || !aggregationAttention || !aggregationPosition;
  const thereIsPackages = totalPackages > 0;

  const routingCode = getSelectedRoutingCode();

  const countPackageDisplay = count => {
    switch (count) {
      case 0:
        return TEXT.NONE_PACKAGE;
      case 1:
        return `${count} ${TEXT.PACKAGE}`;
      default:
        return `${count} ${TEXT.PACKAGE}s`;
    }
  };

  const { view, viewFilterBy } = content;
  useEffect(() => {
    (async () => {
      const csvLink = await generateCsvLink({ view, viewFilterBy });
      setDownloadCsvLink(csvLink);
    })();
  }, [view, viewFilterBy]);

  useEffect(() => {
    if (
      enableExtendedDeadline &&
      JSON.parse(
        localStorage.getItem('EXTENDED_DEADLINE_ONBOARDING_COMPLETED')
      ) !== true
    ) {
      setShowExtendedDeadlineOnboarding(true);
      localStorage.setItem(
        'EXTENDED_DEADLINE_ONBOARDING_COMPLETED',
        JSON.stringify(true)
      );
    }
  }, [enableExtendedDeadline]);

  useEffect(() => {
    setSummary(
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          display="flex"
          justify="space-between"
        >
          <Grid item xs>
            <Typography variant="h4">
              <strong>{TEXT.BASE}</strong>
            </Typography>
            <Box mt={2}>
              <Typography variant="h4">
                {loading ? (
                  <Skeleton
                    variant="rect"
                    width="150px"
                    className={classes.skeleton}
                    height="42px"
                  />
                ) : (
                  countPackageDisplay(totalPackages)
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <BaseHeaderButtons
              downloadCsvLink={downloadCsvLink}
              setCurrentDrawer={setCurrentDrawer}
            />
          </Grid>
        </Grid>
        <Box pt={4} mb={-5}>
          {!loading && <AsyncPackages custodyParam={KEY_CUSTODY.NA_BASE} />}
        </Box>
      </>
    );
  }, [
    setSummary,
    loading,
    thereIsPackages,
    totalPackages,
    classes.skeleton,
    downloadCsvLink
  ]);

  const textAlert =
    getCompanyType() === 'LEVE'
      ? {
          primary:
            TEXT_ALERT_STATUS_HEADER.AGGREGATION_NEW_BASE.WITH_READ_PRIMARY,
          secundary:
            TEXT_ALERT_STATUS_HEADER.AGGREGATION_NEW_BASE.WITH_READ_SECONDARY
        }
      : {
          primary: TEXT_ALERT_STATUS_HEADER.AGGREGATION_NEW_BASE.PRIMARY,
          secundary: TEXT_ALERT_STATUS_HEADER.AGGREGATION_NEW_BASE.SECONDARY
        };

  const existsAtLeastOneValueInAggregationDeadline =
    aggregationDeadline && aggregationDeadline.some(i => i.value !== 0);

  const existsAtLeastOneValueInAggregationAttention =
    aggregationAttention && aggregationAttention.some(i => i.value !== 0);

  const existsAtLeastOneValueInAggregationPosition =
    aggregationPosition && aggregationPosition.some(i => i.value !== 0);

  useEffect(() => {
    setAlertContent(
      <Box>
        <AlertStatusHeader
          textPrimary={textAlert.primary}
          textSecondary={textAlert.secundary}
        />
      </Box>
    );
  }, [setAlertContent, textAlert.primary, textAlert.secundary]);

  const ShowCardReceivePackage = () => {
    return (
      <Can actions={[ACTIONS.RECEIVE_READ]}>
        <CardReceivePackageContainer />
      </Can>
    );
  };

  const isAgency = getCompanyType() === 'AGENCY';
  const isLeve = getCompanyType() === 'LEVE';

  return (
    <>
      <DialogExtendedDeadlineComponent
        openDialog={showExtendedDeadlineOnboarding}
        setOpenDialog={setShowExtendedDeadlineOnboarding}
      />
      <Box display="flex" justifyContent="space-between">
        <Box mx={1} mb={5} mt={4.5}>
          <BaseFilter
            filterSender={isAgency}
            filterCitiesAttend={
              isLeve && CanFunction({ customDisabledTo: 'loggiPonto' })
            }
            filterCircles={
              hasCirclesEnabled &&
              CanFunction({ customDisabledTo: 'loggiPonto' })
            }
          />
        </Box>
        <Hidden smUp>
          <Box maxWidth="40vw">
            <ShowCardReceivePackage />
          </Box>
        </Hidden>
        <Hidden xsDown data-testid="should-show-in-desktop-profile-component">
          <ShowCardReceivePackage />
        </Hidden>
      </Box>

      {(thereIsPackages || loading) && (
        <>
          {aggregationDeadline ? (
            existsAtLeastOneValueInAggregationDeadline && (
              <CardBase title={TEXT.TITLE_PACKAGES_BY_DEADLINE}>
                <Grid container>
                  {aggregationDeadline.map(
                    item =>
                      item.value !== 0 && (
                        <Grid item key={item.label}>
                          <CardFrame
                            key={aggregationDeadline.label}
                            title={TEXT_DEADLINE[item.label]}
                            packageNumber={item.value}
                            url={DEADLINE_LABEL_TO_ROUTE[item.label]}
                          />
                        </Grid>
                      )
                  )}
                </Grid>
              </CardBase>
            )
          ) : (
            <CardFrameSkeleton
              title={TEXT.TITLE_PACKAGES_BY_DEADLINE}
              numberCardFrame={4}
            />
          )}
          {aggregationAttention ? (
            existsAtLeastOneValueInAggregationAttention && (
              <CardBase title={TEXT.TITLE_TO_PAY_ATTENTION}>
                <Grid container>
                  {aggregationAttention.map(item => (
                    <Grid item key={item.label}>
                      {item.value !== 0 && (
                        <CardFrame
                          data-testid={aggregationAttention.label}
                          key={aggregationAttention.label}
                          title={TEXT_ATTENTION[item.label]}
                          packageNumber={item.value}
                          url={ATTENTION_LABEL_TO_ROUTE[item.label]}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </CardBase>
            )
          ) : (
            <CardFrameSkeleton
              title={TEXT.TITLE_TO_PAY_ATTENTION}
              numberCardFrame={2}
            />
          )}
          {aggregationPosition ? (
            existsAtLeastOneValueInAggregationPosition && (
              <CardBase title={TEXT.TITLE_PACKAGES_BY_POSITION}>
                <Grid container>
                  {aggregationPosition.map(item => (
                    <Grid item key={item.label}>
                      {item.value !== 0 && (
                        <CardFrame
                          data-testid={aggregationPosition.label}
                          key={aggregationPosition.label}
                          title={TEXT_POSITION[item.label]}
                          packageNumber={item.value}
                          url={POSITION_LABEL_TO_ROUTE[item.label]}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </CardBase>
            )
          ) : (
            <CardFrameSkeleton
              title={TEXT.TITLE_PACKAGES_BY_POSITION}
              numberCardFrame={2}
            />
          )}
        </>
      )}

      {!thereIsPackages && !loading && getCompanyType() === 'LEVE' && (
        <Grid
          container
          spacing={6}
          justify="center"
          alignItems="center"
          data-testid="empty-content"
        >
          <Grid item xs={5}>
            <Box
              className={clsx(
                classesIcon.iconStyle,
                classesIcon.variantsecondary,
                classesIcon.sizelarge
              )}
              mx="auto"
              my={5}
            >
              <ICBase data-testid="ic-base-empty" />
            </Box>
            <Typography color="textSecondary" align="center">
              {CUSTODY_MAP[KEY_CUSTODY.NA_BASE].emptyDetail}
            </Typography>
          </Grid>
        </Grid>
      )}

      {currentDrawer === DRAWERS.GROUPING && (
        <DrawerGroupPackagesContainer
          routingCode={routingCode}
          destinationContext={SEPARATION_CONTEXT}
          handleClosingDrawer={() => setCurrentDrawer('')}
        />
      )}
    </>
  );
}
