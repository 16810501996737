import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import ConfirmDialogComponent from 'shared/confirm-dialog';
import { useGetSelectedRoleBase } from 'auth/access-control';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { SWITCHES_REALTIME } from 'view/constants';
import * as Sentry from '@sentry/browser';
import showSnackbar from 'shared/snackbar';
import { DEFAULT_MESSAGE } from 'app/httpMessage';
import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import { getCirclesInformation } from 'information/routines-management/circles/circles.service';
import { PACKAGE_STATUS_CODE } from 'view/molecules/package-drawer/drawer.constants';
import { playSuccess, playError } from '../sound/index';
import DrawerGroupPackages from './drawer-group-packages.component';
import DrawerGroupPackagesKeyword from './drawer-group-packages-keyword/drawer-group-packages-keyword.component';
import {
  TEXT,
  CLOSE_MODAL_TEXT,
  VIEWS_DRAWER,
  EDITING_GROUP_HEADER_TEXT
} from './drawer-group-packages.constants';
import { BARCODE_NOT_FOUND } from '../card-receive-package/card-receive-package.constants';
import {
  createGroupUnitload,
  movePackagesToGroupService,
  addPackageInList,
  getPackagesByUnitLoad,
  createPackageGroup,
  updatePackageGroup,
  validateAndMovePackagesToGroupService,
  fetchAndValidatePackagesInfoV1
} from './drawer-group-packages.service';
import { useDrawerGroupPackages } from './provider/drawer-group-packages.context';
import InvalidPackageDialogComponent from './invalid-packages-dialog/invalid-packages-dialog.component';

export default function DrawerGroupPackagesContainer(props) {
  const [loading, setLoading] = useState(false);
  const {
    routingCode,
    destinationContext,
    handleClosingDrawer,
    onBarCodeRead,
    licensePlateToEdit,
    initialViewDrawer,
    packageGroupId,
    keywordsToEdit
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showViewDrawer, setShowViewDrawer] = useState(initialViewDrawer);
  const [newPackagesInfo, setNewPackagesInfo] = useState({});
  const [groupUnitLoadLp, setGroupUnitLoadLp] = useState(null);
  const [currentPackagesInfo, setCurrentPackagesInfo] = useState({});
  const [loadingEditData, setLoadingEditData] = useState(!!licensePlateToEdit);
  const [keywords, setKeywords] = useState(keywordsToEdit);
  const [
    openCloseConfirmationDialog,
    setOpenCloseConfirmationDialog
  ] = useState(false);

  const {
    contextLastPackageBeepSuccess,
    contextLastPackageWithValidation,
    listPackagesBeepedContext,
    setListPackagesBeepedContext,
    clearAllInformationAgruparContext,
    setInvalidPackagesInfo,
    invalidPackagesInfo,
    openCloseValidationDialog,
    setOpenCloseValidationDialog
  } = useDrawerGroupPackages();

  const fsEnableFetchAndValidationPackagesInfoV1NewFs = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsEnableFetchAndValidationPackagesInfoV1NewFs
  );

  const fsEnableUseContextAgruparV1NewFsNew = useFsRealtimeGeneral(
    SWITCHES_REALTIME.fsEnableUseContextAgruparV1NewFsNew
  );

  const isOPERATOR = useGetSelectedRoleBase() === 'OPERATOR';

  // This FS must be an integer, and `IsFSActiveForCurrentBase` should not be used as usual.
  const FSValues = useContext(FeatureSwitchContext);
  const { sleepForRaceCondition } = FSValues;

  const handleDialog = () => {
    setOpenCloseConfirmationDialog(false);
    clearAllInformationAgruparContext();
    handleClosingDrawer();
  };

  const showNotification = ({ message, error }) => {
    onBarCodeRead(false);
    showSnackbar({
      message,
      variant: error ? 'error' : 'success',
      enqueueSnackbar
    });
  };

  const onRead = async barcode => {
    playSuccess();

    try {
      contextLastPackageWithValidation(null);
      contextLastPackageBeepSuccess(null);

      onBarCodeRead(true);
      setLoading(true);

      if (!groupUnitLoadLp) {
        if (licensePlateToEdit) setGroupUnitLoadLp(licensePlateToEdit);
        else {
          const selectedRoutingCode = routingCode;
          const createdUL = await createGroupUnitload({
            selectedRoutingCode
          });

          setGroupUnitLoadLp(createdUL.data.unitLoad.license_plate);
        }
      }

      try {
        if (fsEnableFetchAndValidationPackagesInfoV1NewFs) {
          const responseByBarcode = await fetchAndValidatePackagesInfoV1({
            barcode,
            destinationContext
          });
          const response = responseByBarcode.data;

          if (response[barcode] && response[barcode].validationMessage) {
            contextLastPackageWithValidation(response[barcode]);

            if (fsEnableUseContextAgruparV1NewFsNew) {
              playError();
              setLoading(false);
            }
          } else {
            const packageExists = listPackagesBeepedContext.some(
              p =>
                p?.packageId ===
                // eslint-disable-next-line camelcase
                response[barcode]?.integrationInfo?.package_id
            );

            const packageStructure = response[barcode];
            packageStructure.barcode = barcode;
            packageStructure.packageId =
              // eslint-disable-next-line camelcase
              response[barcode]?.integrationInfo?.package_id;
            if (!packageExists) {
              setListPackagesBeepedContext(prevPackages => [
                packageStructure,
                ...prevPackages
              ]);
            }
            contextLastPackageBeepSuccess(response[barcode]);
          }
        }
      } catch (error) {
        Sentry.captureException(error);

        if (fsEnableUseContextAgruparV1NewFsNew) {
          const errorMessage =
            error.code === 404
              ? BARCODE_NOT_FOUND(error.barcodeRead)
              : error.message;
          playError();
          showNotification({
            message: errorMessage,
            error: true
          });
          setLoading(false);
        }
      }

      let updatedPackageInfo = [];
      if (!fsEnableUseContextAgruparV1NewFsNew) {
        updatedPackageInfo = await addPackageInList({
          barcode,
          newPackagesInfo,
          currentPackagesInfo,
          listPackagesBeepedContext,
          setListPackagesBeepedContext
        });
      }

      if (!fsEnableFetchAndValidationPackagesInfoV1NewFs) {
        setNewPackagesInfo(updatedPackageInfo);
      }
      setLoading(false);
    } catch (error) {
      const errorMessage =
        error.code === 404
          ? BARCODE_NOT_FOUND(error.barcodeRead)
          : error.message;
      playError();
      showNotification({
        message: errorMessage,
        error: true
      });
      setLoading(false);
    }
  };

  let stepOnlyUseInMessageWhenFail = '';
  const movePackagesToGroup = async packagesInfo => {
    try {
      const sortingContextLpn = `${routingCode} ${destinationContext}`;
      let response = {};

      let packages = Object.values(packagesInfo);

      if (fsEnableUseContextAgruparV1NewFsNew) {
        packages = listPackagesBeepedContext.filter(
          i => !i.currentGroupThisPackage
        );
      }

      setLoading(true);

      stepOnlyUseInMessageWhenFail = 'Criando Grupo';

      if (!licensePlateToEdit) {
        await createPackageGroup({
          licensePlate: groupUnitLoadLp,
          tags: keywords,
          isOperator: isOPERATOR
        });
      }

      stepOnlyUseInMessageWhenFail = 'Movendo Pacotes';
      // bypass race condition during unit load creation/move and data replication on elastic.
      if (fsEnableUseContextAgruparV1NewFsNew) {
        response = await validateAndMovePackagesToGroupService({
          packages,
          destinationContext,
          destinationLpn: groupUnitLoadLp
        });
      } else {
        await movePackagesToGroupService({
          packages,
          sortingContextLpn,
          destinationLpn: groupUnitLoadLp
        });
        clearAllInformationAgruparContext();
      }

      if (
        fsEnableUseContextAgruparV1NewFsNew &&
        response.details != null &&
        Object.keys(response.details).length > 0
      ) {
        setInvalidPackagesInfo([]);

        Object.values(response.details).forEach(detail => {
          const packageStructure = detail;
          packageStructure.barcode = detail?.integrationInfo?.barcode;
          // eslint-disable-next-line camelcase
          packageStructure.packageId = detail?.integrationInfo?.package_id;
          packageStructure.isAwaitingResolution =
            detail?.status?.code === PACKAGE_STATUS_CODE.AWAITING_RESOLUTION;
          packageStructure.promisedDate =
            detail?.awaitingResolutionPromisedDate?.promisedDate?.promisedDate;

          setInvalidPackagesInfo(prevInvalidPackages => [
            ...prevInvalidPackages,
            packageStructure
          ]);
        });

        setLoading(false);
        setOpenCloseValidationDialog(true);
      } else {
        await new Promise(accept => setTimeout(accept, sleepForRaceCondition));

        clearAllInformationAgruparContext();
        setNewPackagesInfo({});
        setLoading(false);
        handleClosingDrawer(groupUnitLoadLp, packages.length, keywords);

        showNotification({
          message: licensePlateToEdit
            ? EDITING_GROUP_HEADER_TEXT.SUCCESS_MESSAGE
            : TEXT.SUCCESS_MESSAGE,
          error: false
        });
      }
    } catch (error) {
      setLoading(false);
      playError();
      showNotification({
        message: `${
          error?.code ? error?.message : DEFAULT_MESSAGE
        }. [${stepOnlyUseInMessageWhenFail}]`,
        error: true
      });
    }
  };

  const updateKeywords = async () => {
    try {
      setLoading(true);

      if (packageGroupId) {
        await updatePackageGroup({
          id: packageGroupId,
          licensePlate: licensePlateToEdit,
          tags: keywords
        });
      } else {
        await createPackageGroup({
          licensePlate: licensePlateToEdit,
          tags: keywords
        });
      }

      setLoading(false);
      handleClosingDrawer(
        licensePlateToEdit,
        Object.values(currentPackagesInfo).length,
        keywords
      );

      showNotification({
        message: TEXT.SUCCESS_MESSAGE,
        error: false
      });
    } catch (error) {
      const errorMessage =
        error?.code === 404 ? DEFAULT_MESSAGE : error.message;

      setLoading(false);
      showNotification({
        message: errorMessage,
        error: true
      });
    }
  };

  const handleValidateEditButton = () => {
    setOpenCloseValidationDialog(false);
  };

  const handleValidateGroupButton = () => {
    setOpenCloseValidationDialog(false);

    const invalidBarcodes = new Set(
      invalidPackagesInfo.map(pkg => pkg.barcode)
    );

    const filteredBeepedContext = listPackagesBeepedContext.filter(
      pkg => !invalidBarcodes.has(pkg.barcode)
    );

    setListPackagesBeepedContext(filteredBeepedContext);
    setOpenCloseValidationDialog(false);
  };

  const operatorExistsInACircle = useCallback(async () => {
    try {
      const response = await getCirclesInformation();
      if (response?.circles?.length <= 0) {
        playError();
        showNotification({
          message: TEXT.OPERATOR_NOT_IN_CIRCLE,
          error: true
        });
        handleClosingDrawer();
      }
    } catch (error) {
      const errorMessage = error.code === 404 ? DEFAULT_MESSAGE : error.message;
      playError();
      showNotification({
        message: errorMessage,
        error: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClosingDrawer]);

  const getPackagesOnUnitLoadInfo = useCallback(async () => {
    try {
      const response = await getPackagesByUnitLoad(licensePlateToEdit);
      const packages = response.packageDetails.reduce((acc, packageDetail) => {
        const packageId = packageDetail.integrationInfo.package_id;
        acc[packageId] = {
          packageId,
          barcode: packageDetail.integrationInfo.barcode,
          ...packageDetail
        };
        return acc;
      }, {});

      const packagesForSaveInContext = response.packageDetails.map(
        packageDetail => {
          const _packageId = packageDetail.integrationInfo.package_id;
          const _barcode = packageDetail.integrationInfo.barcode;
          return {
            packageId: _packageId,
            barcode: _barcode,
            ...packageDetail,
            viewingGroupEditing: true,
            currentGroupThisPackage: licensePlateToEdit
          };
        }
      );
      setListPackagesBeepedContext([...packagesForSaveInContext]);

      setCurrentPackagesInfo(packages);
      setLoadingEditData(false);
    } catch (error) {
      setLoadingEditData(false);
      showSnackbar({
        message: error.message,
        variant: 'error',
        enqueueSnackbar
      });
    }
  }, [enqueueSnackbar, licensePlateToEdit, setListPackagesBeepedContext]);

  useEffect(() => {
    if (licensePlateToEdit) getPackagesOnUnitLoadInfo();
  }, [getPackagesOnUnitLoadInfo, licensePlateToEdit]);

  useEffect(() => {
    if (showViewDrawer === VIEWS_DRAWER.PACKAGES && isOPERATOR) {
      operatorExistsInACircle();
    }
  }, [showViewDrawer, isOPERATOR, operatorExistsInACircle]);

  return (
    <Box>
      {showViewDrawer === VIEWS_DRAWER.PACKAGES && (
        <DrawerGroupPackages
          licensePlateToEdit={licensePlateToEdit}
          newPackagesInfo={newPackagesInfo}
          setNewPackagesInfo={setNewPackagesInfo}
          loading={loading}
          setLoading={setLoading}
          loadingEditData={loadingEditData}
          onRead={onRead}
          currentPackagesInfo={currentPackagesInfo}
          setOpenCloseConfirmationDialog={setOpenCloseConfirmationDialog}
          setShowViewDrawer={setShowViewDrawer}
          movePackagesToGroup={movePackagesToGroup}
        />
      )}
      {showViewDrawer === VIEWS_DRAWER.KEYWORDS && (
        <DrawerGroupPackagesKeyword
          currentPackagesInfo={currentPackagesInfo}
          newPackagesInfo={newPackagesInfo}
          loading={loading}
          keywords={keywords}
          setKeywords={setKeywords}
          movePackagesToGroup={movePackagesToGroup}
          setShowViewDrawer={setShowViewDrawer}
          setOpenCloseConfirmationDialog={setOpenCloseConfirmationDialog}
          updateKeywords={updateKeywords}
        />
      )}
      {openCloseConfirmationDialog && (
        <ConfirmDialogComponent
          open
          title={CLOSE_MODAL_TEXT.title}
          description={CLOSE_MODAL_TEXT.description}
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row-reverse' }}
          >
            <Box>
              <Button
                fullWidth
                data-testid="dialog-confirm"
                size="large"
                variant="contained"
                color="primary"
                onClick={handleDialog}
              >
                {CLOSE_MODAL_TEXT.confirmation}
              </Button>
            </Box>
            <Box pt={{ xs: 2.5, sm: 0 }} mr={{ xs: 0, sm: 1 }}>
              <Button
                fullWidth
                data-testid="dialog-back"
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => setOpenCloseConfirmationDialog(false)}
              >
                {CLOSE_MODAL_TEXT.cancel}
              </Button>
            </Box>
          </Box>
        </ConfirmDialogComponent>
      )}
      {openCloseValidationDialog && (
        <InvalidPackageDialogComponent
          editButtonCallback={handleValidateEditButton}
          groupButtonCallback={handleValidateGroupButton}
        />
      )}
    </Box>
  );
}

DrawerGroupPackagesContainer.propTypes = {
  destinationContext: PropTypes.string.isRequired,
  routingCode: PropTypes.string.isRequired,
  handleClosingDrawer: PropTypes.func.isRequired,
  onBarCodeRead: PropTypes.func,
  licensePlateToEdit: PropTypes.string,
  initialViewDrawer: PropTypes.string,
  packageGroupId: PropTypes.string,
  keywordsToEdit: PropTypes.arrayOf(PropTypes.string)
};

DrawerGroupPackagesContainer.defaultProps = {
  onBarCodeRead: () => {
    // Do nothing
  },
  licensePlateToEdit: null,
  initialViewDrawer: VIEWS_DRAWER.PACKAGES,
  packageGroupId: null,
  keywordsToEdit: []
};
