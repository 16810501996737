import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, InputBase, Fab, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { ReactComponent as Logo } from 'images/logo-small.svg';
import { AUTHORIZED_ROUTES, SWITCHES, SWITCHES_REALTIME } from 'view/constants';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';

import { ProfileContainer } from 'profile';

import {
  Can,
  CanFunction,
  IsFSActiveForCurrentBase,
  OptionsForDC
} from 'auth/access-control';
import { ACTIONS } from 'auth/access-control/access-control.rules';

import { getSelectedRoutingCode } from 'auth/login/login.service';
import ButtonNotificationsComponent from 'information/notifications/button/button.component';
import {
  MENU_GROUP_DRIVER_TRACKING,
  MENU_GROUP_MOVE,
  MENU_GROUP_FINANCE,
  HELP_LEVE_URL,
  MENU_USERS,
  MENU_GROUP_CARRIER,
  MENU_ROUTINES,
  MENU_GROUP_TRACKING_WITH_OFFER_NEW_BASE,
  MENU_GROUP_TRACKING_OPERATOR,
  MENU_GROUP_MOVE_DELIVERERS,
  MENU_DISPUTES
} from './menu.constants';
import { useStyles } from './menu.styles';
import MenuGroup from './menu-group';

const MenuComponent = props => {
  const { historyPush } = props;

  const enableNotifications = IsFSActiveForCurrentBase('enableNotifications');

  const enableNewInStreet = IsFSActiveForCurrentBase(
    SWITCHES.enableNewInStreet
  );

  const enableMenuDisputes = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableMenuDisputes
  );

  const [term, setTerm] = useState('');
  const [placeholder, setPlaceholder] = useState('Buscar');

  const classes = useStyles();

  const handleMenuClick = destinationPath => {
    const rc = getSelectedRoutingCode();
    historyPush(`/${rc}${destinationPath}`, rc);
  };

  /* Start block for search bar */
  const onSubmit = e => {
    e.preventDefault();
    const param = term;
    setTerm('');
    const rc = getSelectedRoutingCode();
    historyPush(`/${rc}/acompanhamento/resultado/${param}`);
  };
  const onChange = event => {
    setTerm(event.target.value);
  };

  const onFocus = () => {
    setPlaceholder('Pacotes, ruas, entregadores...');
  };

  const onBlur = () => {
    setPlaceholder('Buscar');
  };
  /* End block for search bar */

  const whatInStreet = item => {
    if (item.title === 'Na rua' && enableNewInStreet) {
      return {
        title: 'Na rua',
        path: AUTHORIZED_ROUTES.INFORMATION.IN_STREET
      };
    }
    return item;
  };

  return (
    <Box className={classes.root}>
      <Box pt={3.5} display="flex" alignItems="center">
        <Logo
          className={classes.logo}
          data-testid="logo"
          onClick={() => handleMenuClick(AUTHORIZED_ROUTES.INFORMATION.TODAY)}
        />
      </Box>
      <Grid container className={classes.maxWidth}>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Can actions={[ACTIONS.TRACKING_READ]}>
                <MenuGroup
                  menuGroup={MENU_GROUP_TRACKING_WITH_OFFER_NEW_BASE.map(item =>
                    whatInStreet(item)
                  )}
                />
              </Can>
              <Can actions={[ACTIONS.CARRIER_MENU]}>
                <MenuGroup
                  menuGroup={MENU_GROUP_CARRIER.map(item => whatInStreet(item))}
                />
              </Can>

              <Can actions={[ACTIONS.TRACKING_OPERATOR]}>
                <MenuGroup
                  menuGroup={MENU_GROUP_TRACKING_OPERATOR.map(item =>
                    whatInStreet(item)
                  )}
                />
              </Can>

              <Can actions={[ACTIONS.ASSIGNMENT_MENU_READ]}>
                <MenuGroup menuGroup={MENU_GROUP_MOVE_DELIVERERS} />
              </Can>

              <Can actions={[ACTIONS.COMPLETE_MOVEMENT_MENU_READ]}>
                <MenuGroup
                  menuGroup={MENU_GROUP_MOVE.filter(item => {
                    if (
                      item.title === 'Distribuir' &&
                      !CanFunction({ customDisabledTo: 'loggiPonto' })
                    ) {
                      return false;
                    }
                    return true;
                  })}
                />
              </Can>

              <Can actions={[ACTIONS.MENU_FINANCE_READ]}>
                <MenuGroup menuGroup={MENU_GROUP_FINANCE} />
              </Can>

              <Can actions={[ACTIONS.USER_MANAGEMENT_READ]}>
                <MenuGroup menuGroup={MENU_USERS} />
              </Can>
              <Can
                whenDC={OptionsForDC.IS_PRESENT}
                includeFS="enableNewTrackingDrivers"
                actions={[ACTIONS.DELIVERER_READ]}
              >
                <MenuGroup menuGroup={MENU_GROUP_DRIVER_TRACKING} />
              </Can>
              <Can
                whenDC={OptionsForDC.IS_ABSENT}
                actions={[ACTIONS.DELIVERER_READ]}
              >
                <MenuGroup menuGroup={MENU_GROUP_DRIVER_TRACKING} />
              </Can>
              <Can
                whenDC={OptionsForDC.IS_PRESENT}
                actions={[ACTIONS.ROUTINES_READ]}
              >
                <MenuGroup menuGroup={MENU_ROUTINES} />
              </Can>
              {enableMenuDisputes && (
                <Can actions={[ACTIONS.MENU_DISPUTES_READ]}>
                  <MenuGroup menuGroup={MENU_DISPUTES} />
                </Can>
              )}
            </Box>
          </Grid>
        </Grid>

        {/* component for search bar */}
        <Grid
          container
          item
          xs={6}
          justify="flex-end"
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <form onSubmit={onSubmit}>
              <Can actions={[ACTIONS.SEARCH_PACKAGES_READ]}>
                <Box
                  className={`${classes.search} ${
                    placeholder !== 'Buscar'
                      ? classes.searchActive
                      : classes.searchFontWeight
                  }
                  ${classes.hover}`}
                >
                  <Box className={classes.searchIcon}>
                    <SearchIcon />
                  </Box>
                  <InputBase
                    value={term}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                    data-testid="input-search"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Box>
              </Can>
            </form>
          </Grid>
          {/* end component for search bar */}
          {enableNotifications && (
            <Grid item data-testid="grid-item-notifications">
              <ButtonNotificationsComponent />
            </Grid>
          )}
          <Can actions={[ACTIONS.HELP_REDIRECT_LEVE]}>
            <Grid item>
              <Fab
                href={HELP_LEVE_URL}
                target="_blank"
                className={classes.roundButton}
              >
                <Typography variant="body1">
                  <em>?</em>
                </Typography>
              </Fab>
            </Grid>
          </Can>
          <Grid item>
            <ProfileContainer />
          </Grid>
        </Grid>
      </Grid>
      <Box
        pt={3.5}
        display="flex"
        alignItems="center"
        className={classes.logo}
      />
    </Box>
  );
};

MenuComponent.propTypes = {
  historyPush: PropTypes.func.isRequired
};

export default MenuComponent;
