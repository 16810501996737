import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useStyles from './invalid-packages-dialog.styles';
import { TEXT } from './invalid-packages-dialog.constants';
import CardInvalidPackageInfo from './invalid-card-package-info/card-invalid-package-info.component';
import { useDrawerGroupPackages } from '../provider/drawer-group-packages.context';

export default function InvalidPackageDialogComponent({
  editButtonCallback,
  groupButtonCallback
}) {
  const classes = useStyles();

  const {
    invalidPackagesInfo,
    openCloseValidationDialog
  } = useDrawerGroupPackages();

  return (
    <Dialog
      open={openCloseValidationDialog}
      data-testid="invalid-package-dialog"
      fullWidth
    >
      <Box px={3} py={1.5}>
        <DialogTitle className={classes.dialogWithoutPadding}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="h6">
              <em>{TEXT.TITLE}</em>
            </Typography>
          </Box>
        </DialogTitle>
        <Box pt={0} mb={3.5}>
          <DialogContent className={classes.dialogWithoutPadding}>
            <Box pb={1} flexDirection="column">
              <Typography variant="body1">{TEXT.WARNING}</Typography>
            </Box>
            <Box
              borderRadius={8}
              border="1px solid #E6E6E6"
              style={{ overflow: 'hidden' }}
            >
              {invalidPackagesInfo.map(pkg => (
                <CardInvalidPackageInfo
                  key={pkg.barcode}
                  barcode={pkg.barcode}
                  companyName={pkg.companyName}
                  destinationCity={pkg.destinationCity}
                  promisedDate={pkg.promisedDate}
                  missedPromisedDateEvents={pkg.missedPromisedDateEvents}
                  isAwaitingResolution={pkg.isAwaitingResolution}
                  validationMessage={pkg.validationMessage}
                />
              ))}
            </Box>
          </DialogContent>
        </Box>
        <DialogActions className={classes.dialogWithoutPadding}>
          <Button
            onClick={() => {
              editButtonCallback();
            }}
            color="primary"
            variant="text"
            data-testid="confirmation-dialog-submit-btn-redirect-distribuir"
          >
            {TEXT.EDIT_BUTTON}
          </Button>
          <Button
            onClick={() => {
              groupButtonCallback();
            }}
            color="primary"
            variant="contained"
            data-testid="confirmation-dialog-submit-btn-redirect-distribuir"
          >
            {TEXT.GROUP_BUTTON}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

InvalidPackageDialogComponent.propTypes = {
  editButtonCallback: PropTypes.func.isRequired,
  groupButtonCallback: PropTypes.func.isRequired
};
