import { Hub } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import HUB from './aws_amplify.constants';

Hub.listen(HUB.AUTH_CHANNEL, async data => {
  const { payload } = data;
  if (payload?.event === HUB.TOKEN_FAILURE_MESSAGE.EVENT) {
    const _code = payload?.data?.code;
    const _name = payload?.data?.name;
    const _event = payload?.event;
    const _message = payload?.message;

    const customError = new Error(`${HUB.TOKEN_FAILURE_MESSAGE.DATA}-${_code}`);

    Sentry.setExtras({
      code: _code,
      name: _name,
      event: _event,
      message: _message,
      file: 'aws_amplify.config.js'
    });

    Sentry.captureException(customError);

    window.location.reload();
  }
});
