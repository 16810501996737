export const ROUTING_CODE = '/:routingcode';

export const PUBLIC_ROUTES = {
  LOGIN_ROUTE: '/',
  SIGNUP_ROUTE: '/cadastro',
  FORGOT_PASSWORD_ROUTE: '/recuperar-senha',
  VALIDATE_PASSWORD_RECOVERY: '/confirmar-recuperacao'
};

export const AUTHORIZED_ROUTES = {
  INFORMATION: {
    TODAY: '/hoje',
    BASE: '/na-base',
    IN_STREET: '/na-rua',
    COLLECTION: '/colecoes',
    GROUPS: '/grupos',
    PACKAGES: '/pacotes',
    HIDDEN_BASE: '/hidden/na-base'
  },
  OFFER: {
    LIST: '/ofertas',
    DETAIL: '/oferta/:offerId',
    ROUTE_MAP: '/oferta/:offerId/rota'
  },
  TRACKING: {
    PATH: `/acompanhamento`,
    IN_STREET: '/acompanhamento/na-rua',
    IN_BASE: '/acompanhamento/na-base',
    IN_BASE_TODAY: '/na-base/colecao/hoje',
    IN_BASE_TOMORROW: '/na-base/colecao/amanha',
    IN_BASE_DELAYED: '/na-base/colecao/atrasado',
    IN_BASE_AFTER_TOMORROW: '/na-base/colecao/depois',
    IN_BASE_FAULTED: '/na-base/colecao/com-falta',
    IN_BASE_SUSPICIOUS_GEOCODING: '/na-base/colecao/problema-localizacao',
    IN_BASE_NO_DEADLINE: '/na-base/colecao/sem-prazo',
    IN_BASE_AT_RISK: '/na-base/colecao/chance-de-extravio',
    IN_BASE_AWAITING_RESOLUTION: '/na-base/colecao/aguardando-tratativa',
    IN_BASE_RECEIVE: '/na-base/colecao/recebimento',
    FINISHED: '/acompanhamento/finalizados',
    IN_GROUPS: '/em-grupos',
    PARAMS_VALIDATION: '(na-rua|finalizados|na-base)',
    PARAMS_VALIDATION_IN_BASE:
      '(hoje|amanha|atrasado|depois|com-falta|problema-localizacao|sem-prazo)',
    PARAMS_VALIDATION_IN_GROUPS: '(na-rua|na-base)',
    CUSTODY_BASE: '/:custody(na-base)',
    ARRIVALS: '/acompanhamento/vai-chegar',
    DISPUTES: '/acompanhamento/finalizados/com-pendencia',
    TO_TRANSFER: '/acompanhamento/para-transferir',
    DEV_DISPUTES: {
      PATH: '/acompanhamento/acareacoes',
      PENDING: '/em-aberto',
      CLOSED: '/encerradas'
    }
  },
  MOVE: {
    DELIVERERS: '/distribuir'
  },
  FINANCE: {
    PATH: `/valores`,
    SUMMARY: '/valores/resumo',
    CLOSE: '/valores/fechado',
    OPEN: '/valores/aberto',
    PREVIOUS: '/valores/anteriores',
    PARAMS_VALIDATION: '(fechado|aberto|anteriores)'
  },
  USERS_MANAGEMENT: {
    PATH: '/pessoas',
    DRIVERS: '/para-rua',
    OPERATORS: '/para-base'
  },
  DRIVERS_TRACKING: '/acompanhamento/teste-atividades',
  ROUTINES: {
    COMPLEX_AREAS: '/areas-complexas',
    DEMAND_REGIONS: '/regioes-demanda'
  },
  ROUTINES_MANAGEMENT: {
    PATH: '/rotinas',
    BASE: '/base',
    CIRCLES: '/circulo',
    CREATE_CIRCLES: '/criar-circulo'
  }
};

export const AUTHENTICATED_ROUTES = {
  CREATE_NEW_PASSWORD_ROUTE: '/criar-nova-senha',
  IMPERSONATE: '/representar',
  IMPERSONATE_REDIRECT: `/representado${AUTHORIZED_ROUTES.INFORMATION.TODAY}`
};

export const ORDERBY_COLUMN = {
  ID: 'id',
  STATUS_CODE: 'status_code',
  STATUS_UPDATE: 'status_updated',
  RECIPIENT_NAME: 'recipient_name',
  RECIPIENT_PHONE: 'recipient_phone',
  RECIPIENT_NIF: 'recipient_nif',
  DESTINATION: {
    ADDRESS_ST: 'destination__address_st',
    ADDRESS_NUMBER: 'destination__address_number',
    ADDRESS_COMPLEMENT: 'destination__address_complement',
    ZIPCODE: 'destination__zip',
    VICINITY: 'destination__vicinity',
    CITY: 'destination__city',
    POS: 'destination__pos'
  },
  BARCODE: 'integration_info__barcode',
  COMPANY_NAME: 'company_name',
  FULL_NAME: 'full_name',
  PARENT_FULL_NAME: 'parent_full_name',
  BAG_SEAL: 'bag_seal'
};

export const SIGN_UP_GOOGLE_USER_LINK_REDIRECT =
  '?error_description=PreSignUp+failed+with+error+User';

export const STATUS_PLATFORM_LOCAL_STORAGE_KEY = 'status_platform';

export const SWITCHES = {
  enableNaRuaToggleGroupForLeve: 'enable_na_rua_toggle_group_for_leve',
  enableCreateExpectedDateForDrawerAwaitingResolution:
    'enable_create_expected_date_for_drawer_awaiting_resolution',
  enableUseTMSVaiChegarCard: 'enable_use_tms_vai_chegar_card',
  /**
   * FS para fazer com que, na tela Vai Chegar, o Arco faça um fetch do unit load ao LW antes que ele chame a lógica de agregações .
   * rollout: boolean - true/false
   * ttl: Após o Freeze. A FS só será testada por 2 dias antes do Freeze.
   */
  enableUseTMSArrivalsAggregation: 'enable_use_tms_arrivals_aggregation',
  enableDiscountInformationOnTheList: 'enable_discount_information_on_the_list',
  enableBaseGroupTabView: 'enable_base_group_tab_view',
  enableDisputesFilters: 'enable_disputes_filters', // Remover após rollout da nova página de acareação (dev-disputes)
  sleepForRaceCondition: 'sleep_for_race_condition',
  enableBaseGroupingButton: 'enable_base_grouping_button',
  enableOnboardingV2: 'enable_onboarding_v2',
  anticipatedOfferList: 'anticipated_offer_list',
  /**
   * FS para utilizar o historico do packtrack na gaveta do pacote.
   * rollout: boolean - true/false
   * ttl: 30 dias - Expectaiva de remocao da FS - 15 janeiro 2024
   */
  enablePackageDrawerPacktrackNewFs: 'enable_package_drawer_packtrack_new_fs',
  showAnticipatedOfferRegion: 'show_anticipated_offer_region',
  regionOfferFilter: 'region_offer_filter',
  showAlertPlatformDegradedComponent: 'show_alert_platform_degraded_component',
  /**
   * @describe: FS enables a button that shows the dispute protocol.
   * @rollout: To activate for all bases.
   * @deadline: We will remove this FS until 15/12/2023
   */
  enableDisputeProtocol: 'enable_dispute_protocol',
  /**
   * FS para habilitar limitação de visualização para bases loggiPonto.
   * rollout: lmc:dc
   * ttl: Ate ser possivel identificar por alguma propriedade da base, essa FS provavelmente deve existir
   */
  enableLoggiPontoByIdentificationV2: 'enable_loggi_ponto_by_identification_v2',
  /**
   * FS para habilitar limitação de visualização para bases loggiPonto.
   * rollout: boolean true/false Vai olhar para a lmc.identification, se da match com loggi-ponto ou loggi_ponto
   * ttl: Ate ser possivel identificar por alguma propriedade da base, essa FS provavelmente deve existir
   */
  enableLoggiPontoByLmcDcV2: 'enable_loggi_ponto_by_lmc_dc_v2',
  /**
   * FS para adicionar campo de userRole a inicializacao do hotjar. Com a adicao de userRole poderemos criar campanhas voltadas ao tipo do usuario.
   * rollout: boolean - true/false
   * ttl: 7 dias - Expectaiva de remocao da FS - 27 novembro 2023
   */
  enableUserRoleOnHotjar: 'enable_user_role_on_hotjar',
  enableShowDropOffValueInfo: 'enable_show_dropoff_value_info',
  /**
   * @describe: enables the possibility of overriding FS values in the URL
   * @rollout: Activates for each user who wishes to use it. The users are Developers, QA, and POs
   * @deadline: Permanent
   */
  enableOverrideFs: 'enable_override_fs',
  /**
   * FS para habilitar novo componente de filtros para tela Na Base.
   * @rollout: lmc:dc.
   * ttl: Ate concluir validacao do novo componente de filtros
   */
  enableShowNewFilterComponentInBase:
    'enable_show_new_filter_component_in_base',
  enableNewInStreet: 'enable_new_in_street',
  enableNewInStreetNewV2: 'enable_new_in_street_new_v2',
  enableNewInStreetNewV2DownloadCsv: 'enable_new_in_street_new_v2_download_csv',
  /**
   * FS para habilitar novo componente de menu responsivo
   * @rollout: lmc:dc.
   * ttl: Ate concluir validacao do novo componente. Apos inicio do rollout
   *     a FS provavelmente deve ser removida em 30 dias.
   */
  enableMenuResponsive: 'enable_menu_responsive',
  enableBaseGroupingButtonForOperator:
    'enable_base_grouping_button_for_operator'
};

export const SWITCHES_REALTIME = {
  fsRealtimeEnableConfigTableNaBaseWithDeliverer:
    'config_table_na_base_with_deliverer',
  fsRealtimeEnableDistribuirRedirectFase1:
    'fs_realtime_enable_distribuir_redirect_fase_1',
  fsRealtimeEnableDistribuirRedirectFase2:
    'fs_realtime_enable_distribuir_redirect_fase_2',
  fsRealtimeDisableRefusePackageNaoVeio:
    'fs_realtime_disable_refuse_package_nao_veio',
  fsRealtimeEnableFetchGroupItIsAllocatedInDrawer:
    'fs_realtime_enable_fetch_group_it_is_allocated_in_drawer',
  /**
   * FS para desabilitar input no Agrupar, se ainda tiver em loading o ultimo beep
   * @rollout: false/true
   * ttl: FS apenas para garantir o fluxo. Pode ser removida em 30 dias.
   */
  fsRealtimeEnableDisableInputAgruparInLoading:
    'fs_realtime_enable_disable_input_agrupar_in_loading',
  disableDriverProfilePickupInfo: 'disable_driver_profile_pickup_info',
  /*
   * FS habilita a utilização do novo endPoint de validar e buscar informações do pacote
   * no beep do Agrupar
   * @rollout: lmc:dc - Preferencialmente iniciar com as bases no Rollout
   * do novo App de entregadores
   * ttl: FS apenas para garantir o fluxo. Ainda em fase de validação.
   */
  fsEnableFetchAndValidationPackagesInfoV1NewFs:
    'fs_enable_fetch_and_validation_packages_info_v1_new_fs',
  /**
   * FS possibilita habilitar a FS que faz a request ao novo endPoint
   * mas não habilitar o component de validação ser visivel.
   * Permitindo validar primeiro se tem algum erro no endPoint.
   * @rollout: lmc:dc - Iniciar testando em OCA - Preferencialmente iniciar com as bases no Rollout
   * do novo App de entregadores
   * ttl: FS apenas para garantir o fluxo. Ainda em fase de validação.
   */
  fsRealtimeEnableShowComponentValidationAgruparV1NewFsNew:
    'fs_enable_show_component_validation_agrupar_v1_new_fs_new',
  fsEnableUseContextAgruparV1NewFsNew:
    'fs_enable_use_context_agrupar_v1_new_fs_new',
  /**
   * FS para ignorar o bloqueio da edicao dos Leves mesmo quando o Driver possui pacote em sua custodia (hasPackage), mudanca ira aplicar para todas as bases.
   * @rollout: true.
   * @deadline: Ate concluir validacao do novo componente. Apos inicio do rollout
   *     a FS provavelmente deve ser removida em 30 dias.
   */
  enableSkipPackageCheckOnDriverUpdate:
    'enable_skip_package_check_on_driver_update',

  enableMenuDisputes: 'fs_realtime_enable_menu_disputes',
  enableExtendedDeadline: 'enable_extended_deadline'
};
