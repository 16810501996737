import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Drawer,
  MenuList,
  MenuItem,
  Divider
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useHistory } from 'react-router-dom';
import { ACTIONS } from 'auth/access-control/access-control.rules';
import { AUTHORIZED_ROUTES, SWITCHES, SWITCHES_REALTIME } from 'view/constants';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import {
  Can,
  CanFunction,
  OptionsForDC,
  IsFSActiveForCurrentBase
} from 'auth/access-control';
import { getSelectedRoutingCode } from 'auth/login/login.service';
import { Icon } from '@loggi/loggi-icons';
import {
  MENU_GROUP_TRACKING_WITH_OFFER_NEW_BASE,
  MENU_GROUP_DRIVER_TRACKING,
  MENU_GROUP_MOVE,
  MENU_GROUP_FINANCE,
  MENU_USERS,
  MENU_GROUP_CARRIER,
  MENU_ROUTINES,
  MENU_GROUP_TRACKING_OPERATOR,
  MENU_GROUP_MOVE_DELIVERERS,
  MENU_DISPUTES
} from '../menu.constants';

const ItemComponent = ({ menuItem, handleMenuClick }) => {
  return (
    <>
      <MenuItem
        key={menuItem.path}
        onClick={() => handleMenuClick(menuItem.path)}
      >
        <Icon name={menuItem.iconName} size="small" color={colors.blue[500]} />
        <Typography variant="body2" style={{ paddingLeft: '10px' }}>
          <em>{menuItem.title}</em>
        </Typography>
      </MenuItem>
      <Divider orientation="horizontal" />
    </>
  );
};

ItemComponent.propTypes = {
  menuItem: PropTypes.shape.isRequired,
  handleMenuClick: PropTypes.func.isRequired
};

const DrawerMenuComponent = props => {
  const { openDrawerMenu, handleCloseDrawer } = props;
  const history = useHistory();

  const enableNewInStreet = IsFSActiveForCurrentBase(
    SWITCHES.enableNewInStreet
  );

  const enableMenuDisputes = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableMenuDisputes
  );

  const handleMenuClick = destinationPath => {
    handleCloseDrawer();
    const rc = getSelectedRoutingCode();
    history.push(`/${rc}${destinationPath}`, rc);
  };

  const whatInStreet = item => {
    if (item.title === 'Na rua' && enableNewInStreet) {
      return {
        title: 'Na rua',
        path: AUTHORIZED_ROUTES.INFORMATION.IN_STREET
      };
    }
    return item;
  };

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={openDrawerMenu}
      onClose={handleCloseDrawer}
    >
      <Box minWidth={240}>
        <Box
          data-testid="action-close-drawer-menu"
          ml={2}
          mt={2}
          onClick={() => {
            handleCloseDrawer();
          }}
        >
          <Icon name="ActionClose" size="medium" color={colors.blue[500]} />
        </Box>
        {/* BEGIN - GROUP 01 */}
        <Can actions={[ACTIONS.TRACKING_READ]}>
          <MenuList>
            {MENU_GROUP_TRACKING_WITH_OFFER_NEW_BASE.map(item =>
              whatInStreet(item)
            ).map(menuItem => {
              return (
                <ItemComponent
                  key={menuItem.path}
                  menuItem={menuItem}
                  handleMenuClick={handleMenuClick}
                />
              );
            })}
          </MenuList>
        </Can>

        <Can actions={[ACTIONS.CARRIER_MENU]}>
          <MenuList>
            {MENU_GROUP_CARRIER.map(item => whatInStreet(item)).map(
              menuItem => (
                <ItemComponent
                  key={menuItem.path}
                  menuItem={menuItem}
                  handleMenuClick={handleMenuClick}
                />
              )
            )}
          </MenuList>
        </Can>

        <Can actions={[ACTIONS.TRACKING_OPERATOR]}>
          <MenuList>
            {MENU_GROUP_TRACKING_OPERATOR.map(item => whatInStreet(item)).map(
              menuItem => (
                <ItemComponent
                  key={menuItem.path}
                  menuItem={menuItem}
                  handleMenuClick={handleMenuClick}
                />
              )
            )}
          </MenuList>
        </Can>
        {/* END - GROUP 01 */}

        {/* START - GROUP 02 */}
        <Can actions={[ACTIONS.ASSIGNMENT_MENU_READ]}>
          <MenuList>
            {MENU_GROUP_MOVE_DELIVERERS.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>

        <Can actions={[ACTIONS.COMPLETE_MOVEMENT_MENU_READ]}>
          <MenuList>
            {MENU_GROUP_MOVE.filter(item => {
              return (
                item.title !== 'Distribuir' ||
                CanFunction({ customDisabledTo: 'loggiPonto' })
              );
            }).map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>
        {/* END - GROUP 02 */}

        {/* MENU FINANCE */}
        <Can actions={[ACTIONS.MENU_FINANCE_READ]}>
          <MenuList>
            {MENU_GROUP_FINANCE.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>

        {/* MENU PESSOAS */}
        <Can actions={[ACTIONS.USER_MANAGEMENT_READ]}>
          <MenuList>
            {MENU_USERS.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>

        {/* START - GROUP Gestor de Frota */}
        {/* https://chat.google.com/room/AAAAdqnAL2A/ot8ARzgFCr4/tiF4OPwY8-c?cls=10 */}
        <Can
          whenDC={OptionsForDC.IS_PRESENT}
          includeFS="enableNewTrackingDrivers"
          actions={[ACTIONS.DELIVERER_READ]}
        >
          <MenuList>
            {MENU_GROUP_DRIVER_TRACKING.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>

        <Can whenDC={OptionsForDC.IS_ABSENT} actions={[ACTIONS.DELIVERER_READ]}>
          <MenuList>
            {MENU_GROUP_DRIVER_TRACKING.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>
        {/* END - GROUP 02 - Gestor de Frota */}

        {/* ROUTINES */}
        <Can whenDC={OptionsForDC.IS_PRESENT} actions={[ACTIONS.ROUTINES_READ]}>
          <MenuList>
            {MENU_ROUTINES.map(menuItem => (
              <ItemComponent
                key={menuItem.path}
                menuItem={menuItem}
                handleMenuClick={handleMenuClick}
              />
            ))}
          </MenuList>
        </Can>

        {/* DISPUTES */}
        {enableMenuDisputes && (
          <Can actions={[ACTIONS.MENU_DISPUTES_READ]}>
            <MenuList>
              {MENU_DISPUTES.map(menuItem => (
                <ItemComponent
                  key={menuItem.path}
                  menuItem={menuItem}
                  handleMenuClick={handleMenuClick}
                />
              ))}
            </MenuList>
          </Can>
        )}
      </Box>
    </Drawer>
  );
};

DrawerMenuComponent.propTypes = {
  openDrawerMenu: PropTypes.bool.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired
};

export default DrawerMenuComponent;
