import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import ConfirmDialogComponent from 'shared/confirm-dialog';

import { SWITCHES_REALTIME } from 'view/constants';
import { useFsRealtimeGeneral } from 'infra/firebase/realtime/firebase-realtime-database';
import { TEXTS } from './drivers-profile.constants';
import useStyles from '../drivers.styles';

const DriversProfileUpdateComponent = ({
  onEdit,
  profile,
  updatedProfile,
  handleClosingDrawer,
  setOpenProfile,
  hasErrors
}) => {
  const { hasPackage } = profile?.deliverer || {};
  const skipPackageCheckOnDriverUpdateFS = useFsRealtimeGeneral(
    SWITCHES_REALTIME.enableSkipPackageCheckOnDriverUpdate
  );

  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdate = () => {
    const { fullName, mobileNumber, email, transportType } = updatedProfile;
    const deliverer = {
      id: profile.deliverer.id,
      full_name: fullName,
      mobile: mobileNumber,
      email,
      transport_type: transportType
    };
    onEdit(deliverer);
    handleClosingDrawer();
    setOpenProfile(false);
    setOpen(false);
  };

  return (
    <>
      <ConfirmDialogComponent
        open={isOpen}
        title={TEXTS.EDIT_DRIVER.EDIT_CONFIRMATION_TITLE}
        description={TEXTS.EDIT_DRIVER.EDIT_CONFIRMATION_DESCRIBE}
      >
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          className={styles.button}
          onClick={handleUpdate}
          variant="contained"
          color="primary"
        >
          Atualizar cadastro
        </Button>
      </ConfirmDialogComponent>
      <Button
        disabled={
          (!skipPackageCheckOnDriverUpdateFS && hasPackage) || hasErrors
        }
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleOpen}
      >
        Atualizar cadastro
      </Button>
    </>
  );
};

DriversProfileUpdateComponent.propTypes = {
  onEdit: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    deliverer: PropTypes.shape({
      id: PropTypes.string,
      hasPackage: PropTypes.bool
    })
  }).isRequired,
  updatedProfile: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    cpf: PropTypes.string,
    mobileNumber: PropTypes.string,
    transportType: PropTypes.string
  }).isRequired,
  handleClosingDrawer: PropTypes.func.isRequired,
  setOpenProfile: PropTypes.func.isRequired,
  hasErrors: PropTypes.func.isRequired
};

export default DriversProfileUpdateComponent;
