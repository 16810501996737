import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const DrawerGroupPackagesContext = createContext();

export const DrawerGroupPackagesProvider = ({ children }) => {
  const [lastPackageWithValidation, setLastPackageWithValidation] = useState(
    null
  );
  const [lastPackageBeepSuccess, setLastPackageBeepSuccess] = useState(null);

  const [listPackagesBeepedContext, setListPackagesBeepedContext] = useState(
    []
  );
  const [invalidPackagesInfo, setInvalidPackagesInfo] = useState([]);
  const [openCloseValidationDialog, setOpenCloseValidationDialog] = useState(
    false
  );

  const clearAllInformationAgruparContext = () => {
    setLastPackageWithValidation(null);
    setLastPackageBeepSuccess(null);
    setListPackagesBeepedContext([]);
  };

  const removePackageListPackagesBeepedContext = packageId => {
    setListPackagesBeepedContext(prevPackages =>
      prevPackages.filter(pkg => pkg.packageId !== packageId)
    );
  };

  const contextLastPackageWithValidation = data => {
    setLastPackageWithValidation(data);
  };

  const contextLastPackageBeepSuccess = data => {
    setLastPackageBeepSuccess(data);
  };

  return (
    <DrawerGroupPackagesContext.Provider
      value={{
        lastPackageWithValidation,
        contextLastPackageBeepSuccess,
        contextLastPackageWithValidation,
        lastPackageBeepSuccess,
        listPackagesBeepedContext,
        setListPackagesBeepedContext,
        removePackageListPackagesBeepedContext,
        clearAllInformationAgruparContext,
        invalidPackagesInfo,
        setInvalidPackagesInfo,
        openCloseValidationDialog,
        setOpenCloseValidationDialog
      }}
    >
      {children}
    </DrawerGroupPackagesContext.Provider>
  );
};

export const useDrawerGroupPackages = () =>
  useContext(DrawerGroupPackagesContext);

DrawerGroupPackagesProvider.propTypes = {
  children: PropTypes.node.isRequired
};
