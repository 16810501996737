import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Box, CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useAmplifyAuth } from '@loggi/authentication-lib';

import { FeatureSwitchContext } from 'infra/firebase/feature-switch-provider';
import ErrorTracker from 'view/molecules/error-tracker';

import { StateProvider, reducer, initialState } from 'shared/contexts';
import NotificationsProvider from 'information/notifications/notifications.provider';
import { ComplexAreasProvider } from 'information/routines-management/routines/complex-areas/complex-areas.provider';
import { DemandRegionProvider } from 'information/routines-management/routines/demand-regions/demand-regions.provider';

import arcoTheme from 'view/theme';

import { DrawerGroupPackagesProvider } from 'tracking/drawer-group-packages/provider/drawer-group-packages.context';
import PublicRoutes from './routes_public';
import AuthenticatedRoutes from './routes_authenticated';

import { SIGN_UP_GOOGLE_USER_LINK_REDIRECT } from './constants';
import './aws_amplify.config';

function DefaultProvider({ children }) {
  // The main intent of this provider it is to avoid multiple line changes on PRs that adds a new Provider
  // If there is a need to add a new provider to wrap the application, it should be added in DefaultProvider

  return (
    <ErrorTracker>
      <StateProvider initialState={initialState} reducer={reducer}>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          autoHideDuration={7000}
          maxSnack={3}
        >
          <CssBaseline />
          <DemandRegionProvider>
            <ComplexAreasProvider>
              <DrawerGroupPackagesProvider>
                <NotificationsProvider>{children}</NotificationsProvider>
              </DrawerGroupPackagesProvider>
            </ComplexAreasProvider>
          </DemandRegionProvider>
        </SnackbarProvider>
      </StateProvider>
    </ErrorTracker>
  );
}

DefaultProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default () => {
  const {
    federatedSignIn,
    state: { authenticatedUser, loading }
  } = useAmplifyAuth();

  const location = useLocation();

  const { isLoading: isLoadingFS } = useContext(FeatureSwitchContext);

  const isAnyAuthenticated = () => !!authenticatedUser;

  // When the user's email already exists in Cognito but has not yet been linked to the Google provider, the lambda:
  // https://github.com/loggi/py/blob/adcbf25498b3ca420209893b930d4d3add3fd94e/apps/auth/src/lambdas/PreSignUp/main.py#L245 binds the user on the
  // first try. And raises with a specific message.
  // Here we get this message and inform the user that if he tries again he will be able to
  const path = location?.search;

  useEffect(() => {
    if (path?.includes(SIGN_UP_GOOGLE_USER_LINK_REDIRECT)) {
      federatedSignIn('Google');
    }
  }, [federatedSignIn, path]);

  const isLoadingState =
    loading || isLoadingFS || path?.includes(SIGN_UP_GOOGLE_USER_LINK_REDIRECT);

  return (
    <ThemeProvider theme={arcoTheme}>
      <DefaultProvider>
        {!isLoadingState && (
          <>
            {!isAnyAuthenticated() && <PublicRoutes />}
            {isAnyAuthenticated() && <AuthenticatedRoutes />}
          </>
        )}
        {isLoadingState && (
          <Box
            pt={20}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress
              justify="center"
              size={120}
              data-testid="loading-index"
            />
          </Box>
        )}
      </DefaultProvider>
    </ThemeProvider>
  );
};
