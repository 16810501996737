import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PrazoAmpliadoGif from './Prazo_Ampliado.gif';

export const TO_GUIDE = 'Saiba Mais';
export const OK = 'Entendi';
export const TITLE = 'Mais tempo para fazer as entregas';
export const DESCRIPTION =
  'Para melhorar a gestão da sua base, aumentamos o prazo de entrega de alguns pacotes. Agora, eles aparecem com a indicação de “Prazo extra aplicado” na área de detalhamento do pacote.';

export const DIALOG_BODY = {
  description: (
    <>
      <img
        src={PrazoAmpliadoGif}
        alt="Prazo Ampliado"
        style={{ width: '100%', borderRadius: '0 0 10px 10px' }}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingY="16px"
      >
        <Typography variant="h6">
          <em>{TITLE}</em>
        </Typography>
      </Box>
      <Typography variant="body1">{DESCRIPTION}</Typography>
    </>
  )
};
