import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import { colors } from '@loggi/mar';
import MissedEventsBox from 'shared/missed-events-box';
import { dateFormatter } from 'shared/formatters/date';
import { TEXT } from '../invalid-packages-dialog.constants';

const CardInvalidPackageInfo = ({
  barcode,
  companyName,
  destinationCity,
  promisedDate,
  missedPromisedDateEvents,
  isAwaitingResolution,
  validationMessage
}) => {
  return (
    <Box
      width="100%"
      display="flex"
      bgcolor={colors.red[50]}
      borderRadius={0}
      px={5}
      py={2}
      borderBottom="1px solid #E6E6E6"
      data-testid="card-invalid-package-info"
    >
      <Box
        width="80%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={1}
      >
        <Box
          width="65%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography variant="body2">
            <em>#{barcode}</em>
          </Typography>
          <Typography
            variant="body2"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {companyName}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {destinationCity}
          </Typography>
          <Typography variant="body2" color="error" style={{ color: 'dark' }}>
            {validationMessage}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        width="auto"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Divider orientation="vertical" />
        <Box
          pl={1.5}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent={missedPromisedDateEvents ? 'space-between' : 'center'}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
              <em>{isAwaitingResolution ? TEXT.WAIT : TEXT.DELIVERY}</em>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <strong>
                {TEXT.DEADLINE} {dateFormatter(promisedDate)}
              </strong>
            </Typography>
          </Box>
          {missedPromisedDateEvents && (
            <MissedEventsBox count={missedPromisedDateEvents.length} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

CardInvalidPackageInfo.propTypes = {
  barcode: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  destinationCity: PropTypes.string.isRequired,
  promisedDate: PropTypes.string.isRequired,
  missedPromisedDateEvents: PropTypes.arrayOf(PropTypes.shape()),
  isAwaitingResolution: PropTypes.bool.isRequired,
  validationMessage: PropTypes.string.isRequired
};

CardInvalidPackageInfo.defaultProps = {
  missedPromisedDateEvents: []
};

export default CardInvalidPackageInfo;
