import api from 'infra/api/leve';
import { URL_API_V1_DISPUTES_PREFIX } from 'infra/service/constants';
import { DEFAULT_DISPUTE_PER_PAGE } from './disputes.constants';

export const getDisputesData = async ({
  startDate = '',
  endDate = '',
  currentPage = 1,
  filters = '',
  disputeFilters = '',
  orderBy = '',
  disputesPerPage = DEFAULT_DISPUTE_PER_PAGE
} = {}) => {
  const params = {
    startDate,
    endDate,
    filters,
    disputeFilters,
    currentPage,
    orderBy,
    disputesPerPage
  };

  return api
    .get(`${URL_API_V1_DISPUTES_PREFIX}/search`, { params })
    .then(response => response.data);
};

export const updateDisputeResponsible = async (
  disputeId,
  disputeResponsibleUserId
) => {
  const params = {
    disputeId,
    disputeResponsibleUserId
  };
  return api.put(
    `${URL_API_V1_DISPUTES_PREFIX}/update_dispute_responsible`,
    params
  );
};
