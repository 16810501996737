import api from 'infra/api/leve';
import browserGeolocation from 'infra/browser/geolocation';
import db from 'infra/storage/async-elasticsearch-database';
import { packageCacheModel } from 'infra/storage/async-elasticsearch-database.model';
import { getLoggedDCId, getLoggedLMCId } from 'auth/login/login.service';

import {
  GET_RECEIVE_INFO_URL,
  MOVE_PACKAGES_URL,
  URL_API_V1_LAST_MILE_PACKAGES_BARCODE,
  URL_PROXY_V1_PREFIX
} from 'infra/service/constants';
import { RESPONSE_MESSAGES_BY_CODE } from 'app/httpMessage';
import { translateStatusCode, DEFAULT_MESSAGE } from '../httpMessage';
import {
  BARCODE_REFERENCE_BAG,
  BARCODE_REFERENCE_PACKAGE,
  CUSTOM_ERROR_MESSAGES,
  PACKAGE_ORIGIN_REDISPATCH,
  RECEIVE_REDISPATCH_ERROR_CODE
} from './card-receive-package.constants';

const defaultCoords = {
  latitude: '0',
  longitude: '0'
};
const appName = 'arco';

export const getPosition = () =>
  new Promise(resolve => {
    try {
      browserGeolocation.getCurrentPosition(
        position => resolve(position.coords),
        () => resolve(defaultCoords)
      );
    } catch (error) {
      resolve(defaultCoords);
    }
  });

function getMessageFromErrorResponse(status, errorMsg) {
  if (status === 404) {
    return errorMsg;
  }

  return translateStatusCode(status, RESPONSE_MESSAGES_BY_CODE);
}

export const cardReceivePackageService = async ({
  process,
  destination,
  barcode,
  isPhysicalEvidence,
  notes
}) => {
  try {
    const { latitude, longitude } = await getPosition();

    const rawPackages = await api.get(
      `${URL_API_V1_LAST_MILE_PACKAGES_BARCODE}${barcode}`
    );

    const { packages, barcodeReference } = rawPackages.data;

    const packageInfo = packages.map(pkg => {
      const info = pkg;
      info.notes = notes;
      return info;
    });
    const body = {
      unitLoadInfo: {
        process,
        destination,
        distributionCenterId: getLoggedDCId(),
        lastMileCompanyId: getLoggedLMCId()
      },
      packages: packageInfo,
      isPhysicalEvidence,
      latitude,
      longitude
    };
    const response = await api.post(
      '/proxy-unretryable/last-mile/v1/leve/move/package_list',
      body
    );

    db.savePackages(
      response.data.successPackages.map(pkg =>
        packageCacheModel(pkg.packageId, pkg.sortingRecordId, pkg.licensePlate)
      )
    );

    return {
      success: response.data.successPackages,
      failed: response.data.failedPackages,
      barcodeReference,
      barcodeRead: barcode
    };
  } catch (error) {
    const responseError = {
      code: error.response ? error.response.status : null,
      barcodeRead: barcode,
      message: error.response
        ? translateStatusCode(error.response.status, CUSTOM_ERROR_MESSAGES)
        : DEFAULT_MESSAGE
    };

    throw responseError;
  }
};

export const cardUnifiedReceivePackageService = async ({
  barcode,
  sortingContextLpn
}) => {
  try {
    const getReceiveInfo = await api.get(`${GET_RECEIVE_INFO_URL}/${barcode}`, {
      params: {
        no_get_missing_packages: true
      }
    });

    if (getReceiveInfo.data.origin === PACKAGE_ORIGIN_REDISPATCH) {
      const responseError = {
        response: { status: RECEIVE_REDISPATCH_ERROR_CODE }
      };
      throw responseError;
    }

    const sortingContext = await api.post(
      `${URL_PROXY_V1_PREFIX}/base/sorting_context`,
      {
        licensePlate: sortingContextLpn
      }
    );
    const destinationLpn =
      sortingContext.data.sortingContext.decisions[0].destination.licensePlate;

    const moveResponse = await api.post(MOVE_PACKAGES_URL, {
      packageBarcode: barcode,
      unitLoadLpn: destinationLpn,
      sortingContextLpn,
      sideEffectParams: {
        applicationName: appName
      }
    });

    db.savePackages(
      moveResponse.data.packagesInfo.map(packageInfo =>
        packageCacheModel(
          packageInfo.packageId,
          packageInfo.sortingRecordId,
          null,
          packageInfo.sortingContextLicensePlate
        )
      )
    );
    // If we beep a package, 'missingPackages' will be returned, otherwise a 'remainingUnitLoads' will be returned.
    return {
      barcodeReference: getReceiveInfo.data.missingPackages
        ? BARCODE_REFERENCE_PACKAGE
        : BARCODE_REFERENCE_BAG,
      barcodeRead: barcode
    };
  } catch (error) {
    const responseError = {
      code: error.response ? error.response.status : null,
      barcodeRead: barcode,
      message: error.response
        ? getMessageFromErrorResponse(
            error.response.status,
            error.response.errorMsg
          )
        : DEFAULT_MESSAGE
    };

    throw responseError;
  }
};
